import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
// import LogIn from '@/views/User/LogIn.vue'
// import ResetPass from '@/views/User/ResetPass.vue'
// import NotFound from '@/views/pages/NotFound.vue'
// import MasterView from '@/views/MasterView.vue'
import store from '@/store'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: () => import('@/views/MasterView.vue'),
        meta: {
            NotSinglePage: true,
        }
    }, {
        path: '/login',
        name: 'login',
        component: () => import('@/views/User/LogIn.vue'),
        meta: {
            NotSinglePage: false,
        }
    }, {
        path: '/reset',
        name: 'reset',
        component: () => import('@/views/User/ResetPass.vue'),
        meta: {
            NotSinglePage: false,
        }
    }, {
        path: '/home',
        name: 'home',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/MasterView.vue'),
        meta: {
            NotSinglePage: true,
            // Auth: true
        }
    }, {
        path: '/checkout',
        name: 'checkout',
        component: () => import('@/views/Producto/CheckOut.vue'),
        meta: {
            NotSinglePage: true,
            // Auth: true
        }
    }, 
    {
        path: '/setting',
        name: 'setting',
        component: () => import('@/views/Setting/Setting.vue'),
        meta: {
            NotSinglePage: true,
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

router.beforeEach((to, from, next) => {
     if(to.name == 'login'){
 
        store.commit('setclearAlldata', null);
     }
    // to and from are both route objects. must call `next`.
    if (to.matched.some(router => router.meta.Auth)) {
        // if(!store.state.loggedIn){
        //     next('/login');
        // }else{
        next();
        // }
    } else {
        next();
    }
})

export default router
