
import { Options, Vue } from "vue-class-component";
// import MasterView from '@/views/MasterView.vue'; // @ is an alias to /src
import router from "@/router";

import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

@Options({
  components: {
    // MasterView,
    Header,
    Footer,
  },
})
export default class App extends Vue {
  NotSinglePage() {
    return router.currentRoute.value.meta.NotSinglePage;
  }
}
