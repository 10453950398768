
import { Options, Vue } from "vue-class-component";

@Options({props: {
  showModal: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      this.$emit('update:showModal', false);
    },
  },
})
export default class Modal extends Vue {
 
}
