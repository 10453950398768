
  import ClienteData from '@/models/clienteData';
import Productos from '@/models/productos';
import Usuarios from '@/models/usuarios';
import Ventas from '@/models/ventas';
import router from '@/router';
import store from '@/store';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Options } from 'vue-class-component';
import { Vue } from 'vue-class-component'; 
  import Vuex from 'vuex';  
import Modal from './Modal.vue';
 
  @Options({
    components: {
      Modal,
    },
    props: {
    },
    computed: {
      ...Vuex.mapState(['cantidadItem','ventaModel','valorTotal','UserLogued','valorTotal','Impuestos','Subtotal'])
    } ,
    data() {
      return {
        showModal: false,
        dataLoaded: false,
        searchTerm: '',
       clients: [],
      }
    },
    methods: {
    search() {
      
      // Aquí, realiza la llamada al servidor para buscar clientes con el término de búsqueda.
      // A continuación, un ejemplo con datos ficticios:
      axios
      .get(
        `${store.state.UrlServer}${store.state.urlApi}/DataManager/cliente/?Usuario=${
          store.state.UserLogued?.Nombre ?? "**"
        }&Codempresa=${
          store.state.Configuration.Codempresa
        }&data=${this.searchTerm}&param2=${store.state.Codmoneda ?? "R$"}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Codempresa": store.state.Configuration.Codempresa,
            Authorization: "Bearer " + store.state.Configuration.DefaultToken,
          },
        }
      )
      .then(
        (response) => {
           this.clients =response.data.Result;
          this.dataLoaded = true;

        },
        (error) => {
          this.cargandoProductos = false;
          console.log(error);
        }
      );
      
    },
    selectClient(client: any) {
      this.clients = [];
      this.searchTerm = '';
      const datausr = sessionStorage.getItem('UserLogued');
        
        if (datausr) {
            if (datausr.length > 0) {
              let buscarProductos =false;
              const user = JSON.parse(datausr) as Usuarios;
              let antCliente=user.Empresas![0].ClienteData;
        user.Empresas![0].ClienteData = client;
        if (user.Configuration != null) {
          this.ventaModel.Coddoc = user.Configuration.Coddoc;

        }
        if(antCliente && antCliente!.Nivel_precio != client.Nivel_precio){
          buscarProductos =true;
        }
       
        store.commit('setUserLogued', user);
        if(buscarProductos ){
          store.commit('setProductos', []);

        }
        // Si el cliente actual tiene un nivel de precio o el cliente anterior tenia un nivel de precios
         
      }
    }
      this.showModal = false;
    },
    close() {
      this.clients = [];
      this.showModal = false;
    },
  },
  })
  export default class Header extends Vue {
    showModal= false;
    textFilter ='';
    ventaModel = {} as Ventas;
    clinteData ={} as ClienteData;
    AllClientes =[{}] as ClienteData[];
    setglobalFilter(){
     
        
      store.commit('setglobalFilter',this.textFilter);
    }
 isChecout(){
    return this.$route.name == 'checkout' || this.$route.name == 'setting';
 }
    CargarLocal() {
    const data = sessionStorage.getItem('ventaModel');
    if (data) {
      this.ventaModel = JSON.parse(data) as Ventas;
      store.commit('setVentaModel', this.ventaModel);
      store.commit('realizaralculo');
    }
    const datausr = sessionStorage.getItem('UserLogued');
        
        if (datausr) {
            if (datausr.length > 0) {
        const user = JSON.parse(datausr) as Usuarios;

        if (user.Configuration != null) {
          this.ventaModel.Coddoc = user.Configuration.Coddoc;

        }

        store.commit('setUserLogued', user);
         
      }
    }
  }
    navigateToPag(){
        // Confirm
        Swal.fire({
            title:'Confirmar',
            text: 'Si sale se perderan los datos, desea continuar?',
            icon: 'question',
            showCancelButton:true,
            confirmButtonText: 'Sí',
            cancelButtonText:'No',
        }).then((val)=>{
            if(val.isConfirmed){
                store.commit('setUserLogued',null);
                // router.push('/home');
                router.push( {name:'login'} );                 
            }
        }) 
    }
    navigatetoSetting(){
        router.push( {name:'setting'} );
    }

    created() {
      this.CargarLocal();
    }

   


  }


  